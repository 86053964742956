@import "~react-phone-input-2/lib/style.css";

.page-content {
  min-height: 100vh;
}

.modal-xxl {
  --bs-modal-width: 1240px;
}

.react-tel-input .form-control {
  width: 100%;
}

.ck-balloon-panel_visible {
  z-index: 11111;
}

.navbar-brand-logo-mini {
  width: 100% !important;
}

.pac-container {
  z-index: 9999999;
}

.chat-leftsidebar {
  display: flex;
  flex-direction: column;
}

.chat-list {
  flex-grow: 1;
}

.chat-conversation, .chat-list {
  height: calc(100vh - 375px);
  overflow-y: scroll;
}

.no-responsive > .table-responsive {
  overflow-x: initial !important;
}

.spec-table {
  tbody {
    border-width: 3px 0 !important;

    > tr {
      border-width: 0 0.5px !important;
    }
  }

  .spec-td {
    border-width: 0.1px !important;
  }
}

.table-note-class {
  width: 400px;
}

ul.note-ul {
  padding-left: 10px;
}

ul.note-ul li {
  white-space: pre-wrap;
}